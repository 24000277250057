
    export default {
        name: 'Dashboard',

        data() {
            return {
                topics: [
                    {
                        title: 'Budgeting and saving',
                        img: 'budget.jpg'
                    },
                    {
                        title: 'Consumer credit',
                        img: 'credit.jpg'
                    },
                    {
                        title: 'Financial goals',
                        img: 'goals.jpg'
                    },
                    {
                        title: 'Loans and debt',
                        img: 'loans.jpg'
                    },
                    {
                        title: 'Insurance',
                        img: 'insurance.jpg'
                    },
                    {
                        title: 'Investments and retirement',
                        img: 'investments.jpg'
                    },
                    {
                        title: 'Careers and education',
                        img: 'career.jpg'
                    },
                    {
                        title: 'Taxes and tax forms',
                        img: 'taxes.jpg'
                    }
                ]
            };
        }
    };
