import { CallbackType, FRCallback, HiddenValueCallback } from '@forgerock/javascript-sdk';
import { Service } from '@/services/service';

class ForgeRockAMUtilService {
    public static getCallbacksOfType<T extends FRCallback>(callbacks: FRCallback[], type: CallbackType): T[] {
        return callbacks.filter(callback => callback.getType() === type) as T[];
    }

    public static getCallbackOfType<T extends FRCallback>(callbacks: FRCallback[], type: CallbackType): T {
        const callbacksOfType = this.getCallbacksOfType<T>(callbacks, type);
        if (callbacksOfType.length !== 1) {
            throw new Error(`Expected 1 callback of type "${type}", but found ${callbacksOfType.length}`);
        }
        return callbacksOfType[0];
    }

    // Takes a realm path returned by AM's '/json/serverinfo' endpoint and converts it into an extended realm path for '/json/realm/*' endpoints
    public static getRealmUrlPathFromRealmPath(amRealmPath: string): string {
        return amRealmPath === '/' ? '/realms/root' : `/realms/root/realms${amRealmPath}`;
    }

    public static getStageNameFromCallbacks(callbacks: FRCallback []): string | undefined {
        let stageName: string | undefined;
        const hiddenValueCallbacks: HiddenValueCallback[] = this.getCallbacksOfType(callbacks, CallbackType.HiddenValueCallback);
        for (const hiddenValueCallback of hiddenValueCallbacks) {
            if (this.isStageHiddenValueCallback(hiddenValueCallback)) {
                stageName = hiddenValueCallback.getOutputValue('value') as string;
                break;
            }
        }

        return stageName;
    }

    public static isKbaQuestionGroupsCallback(callback: FRCallback): boolean {
        return callback.getType() === CallbackType.HiddenValueCallback &&
            callback.getOutputByName('id', undefined) === 'questionGroups';
    }

    public static isRSADeviceFingerprintCallback(callback: FRCallback): boolean {
        return callback.getType() === CallbackType.HiddenValueCallback &&
            callback.getOutputByName('id', undefined) === 'devReqFP';
    }

    public static isStageHiddenValueCallback(callback: FRCallback): boolean {
        return callback.getType() === CallbackType.HiddenValueCallback &&
            callback.getOutputByName('id', undefined) === 'stage' &&
            typeof callback.getOutputValue('value') === 'string';
    }
}

class UtilService extends Service {
    public static am = ForgeRockAMUtilService;

    public static constants = Object.freeze({
        PASSWORD_ALLOWED_CHARACTERS_REGEX: /^[a-zA-Z0-9!#$&"(),./:?@'-]*$/,
        PASSWORD_MIN_LENGTH: 8,
        PASSWORD_MAX_LENGTH: 32,
        PASSWORD_ONE_LETTER_REGEX: /^.*[a-zA-Z]+.*$/,
        PASSWORD_ONE_NUMBER_REGEX: /^.*[0-9]+.*$/,
        KBA_ANSWERS_REGEX: /^[a-zA-Z0-9 ]*$/
    });

    public static camelOrPascalCaseToKebabCase(str: string): string {
        // The following can't be used yet since iOS/Safari don't support regex look behind assertions
        // return str
        //     .replace(/\B(?:([A-Z])(?=[a-z]))|(?:(?<=[a-z0-9])([A-Z]))/g, '-$1$2')
        //     .toLowerCase();
        // So we're using this alternate implementation instead (until the above works in most browsers)
        return str
            .replace(/\B([A-Z])(?=[a-z])/g, '-$1')
            .replace(/\B([a-z0-9])([A-Z])/g, '$1-$2')
            .toLowerCase();
    }

    public static getFormattedPhone(unformattedPhone: string): string {
        if (unformattedPhone.length !== 10) {
            console.error('getFormattedPhone() function expects a 10-digit number');
            return unformattedPhone;
        }

        return `(${unformattedPhone.substring(0, 3)}) ${unformattedPhone.substring(3, 6)}-${unformattedPhone.substring(6, 10)}`;
    }

    public static getUnformattedPhone(formattedPhoneNumber: string): string {
        const result = formattedPhoneNumber.match(/\d/g);
        return result ? result.join('') : '';
    }

    public static localeMessageDefined(localeMessagePath: string): boolean {
        return this.$vue.$t(localeMessagePath) !== localeMessagePath;
    }

    // Positions dialog based on whether it's accessed from mobile app
    public static dialogPosition(): string {
        return (this.$vue as any).isWebview ? 'dialog-top align-self-start' : '';
    }
}

export default UtilService;
