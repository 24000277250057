<template>
    <nav>
        <v-container fluid class="d-flex justify-space-between align-center">
            <router-link :to="{ name: 'LandingPage' }" class="decoration-none">
                <span class="a">Education</span>
            </router-link>

            <div>
                <template v-if="!this.$root.isAuthenticated">
                    <v-btn depressed color="primary" class="mr-4" @click="$router.push({ name: 'Login' })">Login</v-btn>
                    <v-btn depressed color="white" @click="$router.push({ name: 'Register' })">Sign up</v-btn>
                </template>
                <template v-else>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="white">mdi-account-circle-outline</v-icon>
                        </template>

                        <v-list>
                            <v-list-item v-for="item of menuItems" :key="item.title">
                                <v-list-item-title>
                                    <router-link :to="{ name: item.route }">{{ item.title }}</router-link>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </div>
        </v-container>
    </nav>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: 'AppHeader',

        data() {
            return {
                activeTab: {},
                menuItems: [
                    { title: 'Dashboard', route: 'Dashboard' },
                    { title: 'Logout', route: 'Logout' }
                ]
            };
        }
    });
</script>

<style lang="scss" scoped>
    nav {
        background: $primary;
        border-bottom: 1px solid $gray-10;
    }

    .a {
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 2rem;
    }

    .container {
        min-height: 72px;
    }

    .container--fluid {
        max-width: 1200px !important;
    }

    .decoration-none {
        text-decoration: none;
    }
</style>
