
    export default {
        name: 'LandingPage',

        data() {
            return {
                options: [
                    { title: 'Credit Cards', icon: 'credit_card', link: '#' },
                    { title: 'Banking', icon: 'savings', link: process.env.VUE_APP_ONLINE_BANKING_URL },
                    { title: 'Investing', icon: 'monitoring', link: '#' },
                    { title: 'Mortgages', icon: 'cottage', link: process.env.VUE_APP_HOME_LOAN_URL },
                    { title: 'Student Loans', icon: 'school', link: '#' },
                    { title: 'Business Loans', icon: 'business_center', link: '#' }
                ]
            };
        },

        methods: {
            onOptionClick(link: string) {
                window.location.href = link;
            }
        }
    };
