import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { notificationStore } from '@/services/notification';
import Dashboard from '@/views/Dashboard.vue';
import LandingPage from '@/views/LandingPage.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import Register from '@/views/Register.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        redirect: { name: 'Login' }
    },
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/enroll',
        name: 'Register',
        component: Register,
        props: (route: Route) => ({
            helios: route.query.helios
        })
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: (route: Route) => ({
            failureMessage: route.query.failure,
            internalRedirect: route.query['internal-redirect']
        })
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
    base: '/'
});

// eslint-disable-next-line space-before-function-paren
router.beforeEach(async (to, from, next) => {
    // @ts-ignore
    await router.app.$options.data().appInitializedPromise;

    notificationStore.clearAll(); // Clear all notifications (error, warning, success, and info) by default on route change

    // Auth Required? If so, force login then redirect to the original destination after successful login.
    // @ts-ignore
    if (to.meta.authRequired && !router.app.isAuthenticated) {
        next({ name: 'Login', query: { 'internal-redirect': to.fullPath } });
    } else {
        next();
    }
});

export default router;
