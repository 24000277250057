
    import Vue from 'vue';
    import { AuthResponse, FRAuth, FRLoginFailure, FRLoginSuccess, FRStep, StepType } from '@forgerock/javascript-sdk';
    import StageViewer from '@/components/auth/StageViewer.vue';

    export default Vue.extend({
        name: 'Login',

        components: {
            StageViewer
        },

        props: {
            failureMessage: String,
            internalRedirect: String // This should be the internal route name
        },

        data() {
            return {
                internalRedirectCopy: undefined as string | undefined,
                sdkConfig: null as any | null,
                isFirstStep: true,
                step: undefined as FRStep | undefined,
                errorDetail: null,
                submitInProgress: false
            };
        },

        computed: {
            stepOptions() {
                return {
                    query: (this.$root as any).queryParams
                };
            }
        },

        watch: {
            failureMessage(newValue) {
                if (newValue) {
                    this.processQueryParamsAndRemoveFromAddressBar();
                    this.nextStep(undefined, newValue);
                }
            },
            internalRedirect(newValue) {
                // Setup internal redirect if applicable
                // This is not a duplicate of the redirect handling in 'created()'. Both code blocks are needed to handle edge cases.
                if (newValue) {
                    this.processQueryParamsAndRemoveFromAddressBar();
                }
            }
        },

        created() {
            // This is not a duplicate of the 'internalRedirect' watch. Both code blocks are needed to handle edge cases.
            if (this.internalRedirect) {
                this.processQueryParamsAndRemoveFromAddressBar();
            }

            this.nextStep();
        },

        methods: {
            processQueryParamsAndRemoveFromAddressBar: function() {
                // If there is a pending route change, it means we've already called $router.replace when another @Watch
                // was triggered and the query params have already been processed.
                // @ts-ignore
                if (!this.$router.history.pending) {
                    this.internalRedirectCopy = this.internalRedirect;
                    this.$router.replace({ name: this.$route.name as string });
                }
            },

            async nextStep(previousStep?: FRStep, errorMessage?: string) {
                try {
                    this.submitInProgress = true;
                    const authResponse = await FRAuth.next(previousStep, this.stepOptions) as AuthResponse;

                    this.$notification.clearAll(); // Clear all notifications (error, warning, success, and info) by default on step change
                    if (errorMessage) {
                        this.$notification.error({
                            message: errorMessage
                        });
                    }

                    this.handleResponse(authResponse, previousStep);
                } catch (error) {
                    this.$notification.error({
                        title: this.$t('errors.next-step.title') as string,
                        message: this.$t('errors.next-step.message') as string
                    }, error);
                } finally {
                    this.submitInProgress = false;
                }
            },

            handleResponse(authResponse: AuthResponse, previousStep?: FRStep) {
                let step;

                switch (authResponse.type) {
                    case StepType.LoginSuccess: {
                        this.onLoginSuccess(authResponse as FRLoginSuccess);

                        // Update step in store only so tree properly renders Success node
                        step = { payload: { stage: 'Success' } } as FRStep;
                        break;
                    }
                    case StepType.LoginFailure: {
                        this.onLoginFailure(authResponse as FRLoginFailure);

                        // Update step in store only so tree properly renders Failure node
                        step = { payload: { stage: 'Failure' } } as FRStep;
                        break;
                    }
                    case StepType.Step: {
                        step = authResponse as FRStep;
                        this.isFirstStep = !previousStep;
                        this.step = step;
                        break;
                    }
                }
            },

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onLoginSuccess(loginSuccess: FRLoginSuccess) {
                (this.$root as any).isAuthenticated = true;
                this.$router.push({ name: 'LandingPage' });
            },

            onLoginFailure(loginFailure: FRLoginFailure) {
                const loginFailureDetail = loginFailure.getDetail();
                if (loginFailure.getCode() === 400 && loginFailure.getMessage() === 'Authentication Error: Authentication timeout.') {
                    this.onAuthTimeout();
                } else if (loginFailureDetail && loginFailureDetail.failureUrl) {
                    const httpUrlRegex = /^https?:\/\/.*$/;
                    const failureUrlRegex = /^\/login\?failure=.+$/;
                    const failureUrl = loginFailureDetail.failureUrl;
                    if (failureUrlRegex.test(failureUrl)) {
                        // Internally supported failure URL
                        this.$router.push({ path: failureUrl });
                    } else if (httpUrlRegex.test(failureUrl) && !failureUrl.startsWith(window.location.origin)) {
                        // External failure URL (for testing)
                        window.location.href = failureUrl;
                    } else {
                        this.onLoginFailureFallback();
                    }
                } else {
                    this.onLoginFailureFallback();
                }
            },

            onLoginFailureFallback() {
                console.error('Fallback behavior triggered for the authentication failure. This most likely indicates server-side misconfiguration.');
                this.nextStep(undefined, this.$t('login.errors.generic') as string);
            },

            async onAuthTimeout() {
                if (this.step && this.isFirstStep) {
                    // If the user was already on the first auth screen, just bypass the Auth Timeout dialog and
                    // take them to the next step.
                    /* eslint-disable */
                    // TODO - Make sure the callbacks on the old step and new step are the same. If not, the first step in the auth tree was reconfigured on the server and we should skip this bypass sequence and start a new auth session (after showing the auth timeout dialog).
                    const stepTemp = this.step;                           // The step that had the callback value(s) already set by user
                    await this.nextStep();                                // Reset auth session (i.e. get new auth session)
                    stepTemp.payload.authId = this.step.payload.authId;   // Copy new auth id to old step that was already presented to user
                    this.step = stepTemp;                                 // Set old step as the current step
                    await this.nextStep(this.step);                       // Send the step/callbacks and proceed to next step
                    /* eslint-enable */
                } else {
                    this.$notification.error({
                        title: this.$t('errors.auth-timeout.title') as string,
                        message: this.$t('errors.auth-timeout.message') as string
                    }).then(() => {
                        this.nextStep();
                    });
                }
            }
        }
    });
